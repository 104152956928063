import "css/style.css"
import {lazyLoading} from "./lazyload/lazyload-init"
import "@components/main-nav"
import "@components/main-header/main-header"
import "@components/items/menu-item/menu-item"
import "js/cart-flow/cart-flow"
import "js/snippets/snippets"
import "@components/items/add-wishlist-button-item/add-wishlist-button-item"
import "js/same-height/same-height"
import "@components/items/new-products-item/new-products-item--tabs"
import "@components/sections/top-products-section/top-products-section"
import "@components/groups/tab-controls-group/tab-controls-group"
import "js/watchdog"
import "@components/items/notification-item/notification-item"

window.lazyloading = lazyLoading // add lazyloading to global window object

async function loadFancybox() {
  if (document.querySelectorAll("[data-fancybox]").length) {
    await import("./fancybox")
  }
}
async function loadMain() {
  await import("./main")
  loadFancybox()
}
loadMain()

async function loadSwiper() {
  if (document.querySelectorAll(".swiper").length) {
    await import("./swiper/swiper")
  }
}
loadSwiper()

async function loadModals() {
  if (document.querySelectorAll(".c-modal").length) {
    await import("@components/modal/modal")
  }
}
loadModals()

async function loadFileInput() {
  if (document.querySelectorAll(".f-file").length) {
    await import("@components/fields/file-field/file-field")
  }
}
loadFileInput()

async function loadFlatpickr() {
  if (document.querySelectorAll(".flatpicker, .f-date, .flatpickr-calendar").length) {
    await import("js/flatpickr")
    document.dispatchEvent(new Event("flatpickrInit"))
  }
}
loadFlatpickr()

async function loadPasswordField() {
  if (document.querySelectorAll(".f-text.--password").length) {
    await import("@components/fields/text-field/text-field--password")
  }
}
loadPasswordField()

async function loadFilterList() {
  if (document.querySelectorAll(".js-filter-list").length) {
    await import("./filter/filter-list")
  }
}

async function loadSlimSelect() {
  if (document.querySelectorAll(".slim-select").length) {
    await import("./slim-select/slim-select")
  }
}

loadFilterList().then(loadSlimSelect)

async function loadFaqItem() {
  if (document.querySelectorAll(".i-faq").length) {
    await import("@components/items/faq-item/faq-item")
  }
}
loadFaqItem()

async function loadCheckboxSelectField() {
  if (document.querySelectorAll(".f-checkbox-select").length) {
    await import("@components/fields/checkbox-select-field")
  }
}
loadCheckboxSelectField()

async function loadBaseForm() {
  if (document.querySelectorAll("form.--validate").length) {
    await import("@components/base-form/base-form")
  }
}
loadBaseForm()

async function loadScrollUp() {
  if (document.querySelectorAll(".c-scroll-up").length) {
    await import("@components/scroll-up/scroll-up")
  }
}
loadScrollUp()

async function loadTextField() {
  if (document.querySelectorAll(".f-text, .f-textarea, .f-date").length) {
    await import("@components/fields/text-field/text-field")
  }
}

loadTextField()

async function loadRangeSliderField() {
  if (document.querySelectorAll(".f-range-slider").length) {
    await import("@components/fields/range-slider-field/range-slider-field")
  }
}
loadRangeSliderField()

async function loadMulticheckboxSearchField() {
  if (document.querySelectorAll(".f-multicheckbox-search").length) {
    await import("@components/fields/multicheckbox-search-field/multicheckbox-search-field")
  }
}
loadMulticheckboxSearchField()

async function filtrationItem() {
  if (document.querySelectorAll(".i-filtration").length) {
    await import("@components/items/filtration-item/filtration-item")
  }
}

filtrationItem()

async function loadProductReviews() {
  if (document.querySelectorAll(".product-reviews").length) {
    await import("@components/product-detail/product-reviews/product-reviews")
  }
}
loadProductReviews()

async function countdownItem() {
  if (document.querySelectorAll(".i-countdown").length) {
    await import("@components/items/countdown-item/countdown-item")
  }
}
countdownItem()

async function productOfferItem() {
  if (document.querySelectorAll(".i-product-offer").length) {
    await import("@components/items/product-offer-item/product-offer-item")
  }
}
productOfferItem()

async function loadIcoField() {
  if (document.querySelectorAll(".f-ico").length) {
    await import("@components/fields/ico-field/ico-field")
  }
}
loadIcoField()

async function interpretsFilterForm() {
  if (document.querySelectorAll(".f-interprets-filter").length) {
    await import("@components/forms/interprets-filter-form/interprets-filter-form")
  }
}
interpretsFilterForm()

async function productNavbar() {
  if (document.querySelectorAll(".product-navbar").length) {
    await import("@components/product-detail/product-navbar/product-navbar")
  }
}
productNavbar()

async function counter() {
  if (document.querySelectorAll(".c-counter").length) {
    await import("@components/counter/counter")
  }
}
counter()

async function loadProductParameters() {
  if (document.querySelectorAll(".product-parameters").length) {
    await import("@components/product-detail/product-parameters/product-parameters")
  }
}
loadProductParameters()

async function contentToggleItem() {
  if (document.querySelectorAll(".i-content-toggle").length) {
    await import("@components/items/content-toggle-item/content-toggle-item")
  }
}
contentToggleItem()

async function cartOverview() {
  if (document.querySelectorAll(".w-cart-overview").length) {
    await import("@components/widgets/cart-overview-widget/cart-overview-widget")
  }
}
cartOverview()

async function reclamationItem() {
  if (document.querySelectorAll(".i-reclamation").length) {
    await import("@components/items/reclamation-item/reclamation-item")
  }
}
reclamationItem()

async function loadFilterClear() {
  if (document.querySelectorAll(".js-filter-clear").length) {
    await import("./filter/filter-clear")
  }
}
loadFilterClear()

async function refundMethodForm() {
  if (document.querySelectorAll(".f-refund-method").length) {
    await import("@components/forms/refund-method-form/refund-method-form")
  }
}
refundMethodForm()

async function loadCategories() {
  if (document.querySelectorAll(".s-category").length) {
    await import("@components/sections/category-section/category-section")
  }
}
loadCategories()

async function loadShipingPayment() {
  if (document.querySelectorAll(".s-shipping-and-payment").length) {
    await import("@components/sections/shiping-payment-section/shiping-payment-section")
  }
}
loadShipingPayment()

async function loadFiltrationForm() {
  if (document.querySelectorAll(".f-filtration").length) {
    await import("@components/forms/filtration-form/filtration-form")
  }
}
loadFiltrationForm()

async function loadWishlistButtonItem() {
  if (document.querySelectorAll(".i-wishlist-button").length) {
    await import("@components/items/add-wishlist-button-item/add-wishlist-button-item")
  }
}

loadWishlistButtonItem()

async function loadProductDetail() {
  if (document.querySelectorAll(".product-detail").length) {
    await import("@components/product-detail/product-detail")
  }
}
loadProductDetail()

async function loadAddToCartModal() {
  if (document.querySelectorAll(".m-add-product-to-cart").length) {
    await import("@components/modals/add-product-to-cart-modal/add-product-to-cart-modal")
  }
}
loadAddToCartModal()

async function loadProductsHandle() {
  if (document.querySelectorAll(".s-products-list").length) {
    await import("js/product/product-handle--products")
  }
}
loadProductsHandle()

async function loadEditorialCalendarFilter() {
  if (document.querySelectorAll(".f-editorial-calendar-filter").length) {
    await import("@components/forms/editorial-calendar-filter-form/editorial-calendar-filter-form")
  }
}
loadEditorialCalendarFilter()

async function loadProductReviewForm() {
  if (document.querySelectorAll(".f-product-review").length) {
    await import("@components/forms/product-review-form/product-review-form")
  }
}
loadProductReviewForm()

async function loadPplModal() {
  if (document.querySelectorAll(".s-shipping-and-payment").length) {
    await import("@components/modals/ppl-modal/ppl-modal")
  }
}
loadPplModal()

async function loadBalikovnaModal() {
  if (document.querySelectorAll(".s-shipping-and-payment").length) {
    await import("@components/modals/balikovna-modal/balikovna-modal")
  }
}
loadBalikovnaModal()

async function loadPacketaModal() {
  if (document.querySelectorAll(".s-shipping-and-payment").length) {
    await import("@components/modals/packeta-modal/packeta-modal")
  }
}
loadPacketaModal()

async function loadContentWAside() {
  if (document.querySelectorAll(".content-w-aside__aside.--sticky").length) {
    await import("@components/content-w-aside/content-w-aside")
  }
}
loadContentWAside()

async function loadBtnCopyToClipboard() {
  if (document.querySelectorAll(".btn-copy-to-clipboard").length) {
    await import("@components/button/button-copy-to-clipboard")
  }
}
loadBtnCopyToClipboard()

async function loadPurgeModal() {
  if (document.querySelectorAll("#cart-purge-modal").length) {
    await import("@components/modals/cart-purge-modal/cart-purge-modal")
  }
}
loadPurgeModal()

async function loadYoutubeIframeItem() {
  if (document.querySelectorAll(".i-youtube-iframe").length) {
    await import("@components/items/youtube-iframe-item/youtube-iframe-item")
  }
}
loadYoutubeIframeItem()

async function loadInterpretsCategoryGroup() {
  if (document.querySelectorAll(".g-interprets-category").length) {
    await import("@components/groups/interprets-category-group/interprets-category-group")
  }
}
loadInterpretsCategoryGroup()

async function loadCompanyInputsHandle() {
  if (document.querySelectorAll(".f-user-account, .s-order-delivery-and-payment").length) {
    await import("js/company-inputs-handle/company-inputs-handle")
  }
}
loadCompanyInputsHandle()

async function loadExternalSvgLoader() {
  if (document.querySelectorAll(".c-svg").length) {
    await import("@components/svg")
  }
}
loadExternalSvgLoader()

async function loadOverlayScrollbars() {
  if (document.querySelectorAll(".u-scrollbar").length) {
    await import("./overlayscrollbars")
  }
}
loadOverlayScrollbars()

async function loadAlerts() {
  if (document.querySelectorAll(".g-alerts").length) {
    await import("@components/items/alert-item/alert-item")
  }
}
loadAlerts()

async function loadSearchForm() {
  if (document.querySelectorAll(".f-search").length) {
    await import("@components/forms/search-form/search-form")
  }
}
loadSearchForm()

async function loadSubMenuItem() {
  if (document.querySelectorAll(".i-submenu__category-items").length) {
    await import("@components/items/submenu-item/submenu-item")
  }
}
loadSubMenuItem()

async function loadMediumsGroup() {
  if (document.querySelectorAll(".g-mediums").length) {
    await import("@components/groups/mediums-group/mediums-group")
  }
}
loadMediumsGroup()
